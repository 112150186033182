import "./App.css";
import Navbar from "./Component/Navbar";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import background from "./Assets/grass1.png";
import background2 from "./Assets/samurai.jpg";
import background3 from "./Assets/samurai1.jpg";
import ContentArea1 from "./Component/ContentArea1";
import ContentArea2 from "./Component/ContentArea2";
import ContentArea3 from "./Component/ContentArea3";
import ContentArea4 from "./Component/ContentArea4";
import ContentArea5 from "./Component/ContentArea5";
import ContentArea6 from "./Component/ContentArea6";
import ParticleEffects from "./Component/ParticleEffects";
// Add the required Font Awesome icons to the library
library.add(fab);

function App() {
  return (

    <div>

      <ToastContainer />
      <div className="relative min-h-screen h-auto bg-black">
        <div
          id="home"
          className="absolute top-0 left-0 w-full h-full opacity-50"
          style={{
            backgroundImage: `url(${background})`,
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
          }}
        ></div>

        <div className="relative">

          {" "}
          {/* Add a new relative container */}
          <Navbar />
          <ContentArea1 />
        </div>

      </div>

      <div className="relative z-10 bg-gradient3" >

        <ContentArea2 />
      </div>

      <div className="relative bg-black/60">
        <div
          className="absolute top-0 left-0 w-full h-full -z-10"
          style={{
            backgroundImage: `url(${background2})`,

            backgroundSize: "cover",
          }}
        ></div>

        <div className="relative z-10">
          {" "}
          {/* Add a new relative container with higher z-index */}
          <ContentArea3 />
        </div>
      </div>

      {/* <div
        id="Team"
        className="relative z-10 bg-gradient3">
        <ContentArea4 />
      </div> */}
      <div className="relative z-10 flex flex-col justify-center Banner sm:h-auto lg:min-h-screen bg-gradient2">
        <ContentArea6 />
      </div>
      <div className="relative flex items-center bg-black sm:h-auto lg:h-screen -z-10">
        <div
          className="absolute top-0 left-0 w-full h-full opacity-50"
          style={{
            backgroundImage: `url(${background3})`,
            // backgroundAttachment: "fixed",
            backgroundSize: "cover",
          }}
        ></div>

        <div className="w-full">
          {" "}
          {/* Add a new relative container with higher z-index */}
          <ContentArea5 />
        </div>
      </div>


      <div className="bg-[#e9da24] px-12 flex justify-between items-center  text-white font-bold py-6  sm:py-4 lg:flex-row space-y-4 flex-col">
        <a href="/" className="text-2xl text-white font-main">
          <a href="#home">Joe Mama</a>
        </a>
        <p className="mt-4 text-center font-main sm:mt-0 sm:ml-4 sm:mr-4">
          Copyright @ Joe Mama NFTs | All rights reserved
        </p>
        <div className="flex gap-4 mt-4 cursor-pointer sm:mt-0">
          <a
            href="https://twitter.com/nft_joemama?s=11"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-brands fa-x-twitter border-[1px] border-white p-2 rounded-[100%] hover:bg-white hover:text-black"></i>
          </a>
        </div>
      </div>
    </div >
  );
}

export default App;
