import "../App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { book } from "@fortawesome/free-solid-svg-icons";
import Typewriter from 'typewriter-effect';
import ParticleEffects from "./ParticleEffects";

library.add(fab);

function ContentArea1() {

  return (
    <div className="flex flex-col items-center justify-center p-8 lg:items-start lg:h-max lg:ml-20 rounded-xl">
      <ParticleEffects />
      <h1 className="lg:mt-[200px] mt-[100px] font-main text-white text-4xl lg:text-9xl font-bold py-9">
        <Typewriter
          options={{
            strings: ['Joe Mama NFTs'],
            autoStart: true,
            loop: true,
            pauseFor: 10000,
          }}
        />
      </h1>

    </div>
  );
}

export default ContentArea1;
