import "../App.css";
import { useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import emperorImage from "../Assets/emperor.png";
import sideImage from "../Assets/sideImage.jpg";
import EmperorCard from "./EmperorCard";
import AOS from "aos";
import "aos/dist/aos.css";
import SwiperComponent from "./SwiperComponent";
library.add(fab);

function ContentArea4() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  });
  return (
    <>

      <div className="container mx-auto min-h-screen py-20">
        <SwiperComponent />
        <div className="flex items-center justify-start px-10 text-center lg:mr-3" data-aos="fade-right">
          <div className="flex flex-col items-center justify-center w-full mt-10 lg:items-center">
            <h1 className="text-4xl font-bold text-white font-main lg:text-7xl py-9">
              Our Team
            </h1>
            <p className="items-center my-20 text-sm text-center text-white lg:text-lg font-main">
              Our team has built their skillset over many years of operating in
              the crypto space, united by the common goal of value creation and
              extraction in innovative and creative ways. We now believe it is
              time to share this value with the public in an effort to advance the
              crypto space as a whole and build a powerful community along the
              way.
            </p>
          </div>
        </div>
        {/* <div className="lg:mb-0 mb-20 md:min-w-[600px] w-full flex justify-center items-center" data-aos="zoom-in">
          <EmperorCard />
        </div> */}
      </div>
    </>
  );
}

export default ContentArea4;
