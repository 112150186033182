import "../App.css";
import { useState } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import dash1 from "../Assets/dash1.png";
import * as Scroll from "react-scroll";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

// import { MetaMaskSDK } from '@metamask/sdk';

import logo from "../logo.svg";
import { connectMetamask } from "../blockchain";
library.add(fab);

function Navbar() {
  const [account, setAccount] = useState(null);

  const handleClick = async () => {
    if (account) {
      setAccount(null);
      return;
    }
    try {
      const accounts = await connectMetamask();
      setAccount(accounts);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <nav className="flex flex-col items-center justify-between gap-2 pt-3 overflow-hidden text-white bg-transparent lg:gap-4 lg:p-6 lg:pt-6 lg:flex-row flex-col-3">
        <div className="w-[15rem]"></div>
        <div className="flex items-center justify-center px-2 py-1 space-x-1 bg-gray-600 bg-opacity-50 border-2 border-white rounded-full lg:space-x-6">
          <img
            src={dash1}
            alt="logo"
            className="w-10 p-1 cursor-pointer lg:w-16 animate-shaky"
          />
          <ul className="flex gap-2 text-xs lg:text-lg lg:gap-4">
            <li>
              <Link
                to="home"
                spy={true}
                smooth={true}
                duration={500}
                className="text-white font-main hover:text-black"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="about"
                spy={true}
                smooth={true}
                duration={500}
                className="text-white font-main hover:text-black"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="mint"
                spy={true}
                smooth={true}
                duration={500}
                className="text-white font-main hover:text-black"
              >
                Mint
              </Link>
            </li>

            <li>
              <Link
                to="faq"
                spy={true}
                smooth={true}
                duration={500}
                className="text-white font-main hover:text-black"
              >
                Roadmap
              </Link>
            </li>
          </ul>
          <div className="flex gap-2 px-2">
            {" "}
            {/* Increased gap to create more horizontal gap */}
            <a
              href="https://twitter.com/nft_joemama?s=11"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fa-brands fa-x-twitter border-[1px] border-white lg:p-2 p-1 rounded-[100%] hover:bg-white hover:text-black"></i>
            </a>
          </div>
        </div>
        <div className="">
          <button
            onClick={handleClick}
            type="button"
            className="font-main border-[2px] border-white px-4 py-2 bg-[#201b22] transform transition-all duration-100  hover:scale-110 bg-opacity-40 backdrop-blur-md rounded-full  lg:mr-0 lg:mt-0 mt-4 lg:text-xl text-sm"
          >
            {account
              ? `${account.substring(0, 6)}...${account.substring(
                account.length - 4
              )}`
              : "Connect Wallet"}
            {/* Connect Wallet */}
          </button>
        </div>
      </nav>
    </>
  );
}
export default Navbar;
