import { ethers } from "ethers";

import nftJson from "./FactoryNFT.json";

export const NFTCONTRACTADDRESS = "0x32027c98C166d779A77673fC98a45cc333912B2A";
export const NFTCONTRACTABI = nftJson;

const provider = new ethers.providers.JsonRpcProvider(
  "https://rpc.ankr.com/avalanche"
);
export const readOnlyContract = new ethers.Contract(
  NFTCONTRACTADDRESS,
  NFTCONTRACTABI,
  provider
);

export const getBalance = async (address) => {
  const balance = await provider.getBalance(address);
  return balance.toBigInt();
};
