import "../App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { useEffect } from "react";
import { toast } from "react-toastify";
import sideImage from "../Assets/9.png";
import dash1 from "../Assets/dash1.png";
import dash2 from "../Assets/dash2.png";
import dash3 from "../Assets/dash3.png";
import dash4 from "../Assets/dash4.png";
import dash5 from "../Assets/dash5.png";
import AOS from "aos";
import "aos/dist/aos.css";
library.add(fab);

function ContentArea2() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  });
  return (
    <div
      id="about"
      className="container flex flex-col items-center justify-center h-full px-6 mx-auto md:px-12 lg:flex-row lg:min-h-screen"
    >
      <div
        className="flex flex-col items-center justify-center min-h-screen py-6"
        data-aos="fade-up"
      >
        <h1 className="mt-6 text-2xl font-bold text-center text-white font-main lg:text-start lg:text-5xl">
          Welcome to Joe Mama NFTs on Avalanche!
        </h1>
        <div className="flex flex-col w-full text-sm text-white gap-y-4 lg:mt-12 lg:text-xl lg:text-left font-main">
          <p className="text-center">
            At Joe Mama, we are not only passionate about the boundless
            possibilities that NFTs bring to the world of art, gaming, and
            collectibles, but we're also huge fans of the Avalanche network and
            Trader Joe! Just like how we love our mamas, we cherish the
            innovation, speed, and community that Avalanche brings to the
            blockchain space.
          </p>
          <p className="text-center">
            Our love for Avalanche and Trader Joe has influenced every aspect of
            Joe Mama NFTs, from our choice of blockchain to our commitment to
            fostering a vibrant community of creators and collectors.
          </p>
        </div>
        <div
          className="flex flex-col w-full text-sm text-white gap-y-4 lg:mt-12 lg:text-xl lg:text-left font-main"
          data-aos="zoom-in-up"
        >
          <div className="items-center justify-center lg:flex gap-y-2 lg:mt-12">
            <div className="lg:w-[20rem] flex-col p-4 items-center justify-center border border-white rounded-xl mt-4 lg:m-2 transition-transform transform hover:-rotate-3">
              <img
                src={dash1}
                alt="dash1"
                className="w-8 h-8 mx-auto lg:w-12 lg:h-12"
              />
              <p className="text-center">Self Funded</p>
            </div>
            <div className="transition-transform transform hover:-rotate-3 lg:w-[20rem] flex-col p-4 items-center justify-center border border-white rounded-xl mt-4 lg:m-2">
              <img
                src={dash2}
                alt="dash2"
                className="w-8 h-8 mx-auto lg:w-12 lg:h-12"
              />
              <p className="text-center">0 Team allocations</p>
            </div>
            <div className="transition-transform transform hover:rotate-3 lg:w-[20rem] flex-col p-4 items-center justify-center border border-white  rounded-xl mt-4 lg:m-2">
              <img
                src={dash3}
                alt="dash3"
                className="w-8 h-8 mx-auto lg:w-12 lg:h-12"
              />
              <p className="text-center">No pre mint</p>
            </div>
          </div>
          <div className="items-center justify-center lg:flex gap-y-2">
            <div className="transition-transform transform hover:-rotate-3 lg:w-[20rem] flex-col p-4 items-center justify-center border border-white  rounded-xl mt-4 lg:m-2">
              <img
                src={dash4}
                alt="dash4"
                className="w-8 h-8 mx-auto lg:w-12 lg:h-12"
              />
              <p className="text-center">1000 unique mamas</p>
            </div>
            <div className="transition-transform transform hover:rotate-3 lg:w-[20rem] flex-col p-4 items-center justify-center border border-white  rounded-xl mt-4 lg:m-2">
              <img
                src={dash5}
                alt="dash5"
                className="w-8 h-8 mx-auto lg:w-12 lg:h-12"
              />
              <p className="text-center">Mint gets burned 🔥</p>
            </div>
          </div>
        </div>
        <div
          className="max-w-sm mt-2 text-center text-white font-main overflow-handler md:max-w-md lg:max-w-lg xl:max-w-xl lg:mt-10"
          data-aos="fadu-up"
        >
          To help fund us, donate to our Bitcoin wallet:
        </div>
        <div
          className="flex max-w-sm mt-1 text-center text-white underline gap-x-2 font-main overflow-handler md:max-w-md lg:max-w-lg xl:max-w-xl"
          data-aos="fadu-up"
        >
          bc1qrk28gptwzl04z4zs9dpxwvar6cx797jxsd77ph{" "}
          <span
            className="cursor-pointer "
            onClick={() => {
              navigator.clipboard.writeText("bc1qrk28gptwzl04z4zs9dpxwvar6cx797jxsd77ph");
              toast.success("Address copied to clipboard!");
            }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M21.6602 10.44L20.6802 14.62C19.8402 18.23 18.1802 19.69 15.0602 19.39C14.5602 19.35 14.0202 19.26 13.4402 19.12L11.7602 18.72C7.59018 17.73 6.30018 15.67 7.28018 11.49L8.26018 7.30001C8.46018 6.45001 8.70018 5.71001 9.00018 5.10001C10.1702 2.68001 12.1602 2.03001 15.5002 2.82001L17.1702 3.21001C21.3602 4.19001 22.6402 6.26001 21.6602 10.44Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M15.0603 19.3901C14.4403 19.8101 13.6603 20.1601 12.7103 20.4701L11.1303 20.9901C7.16034 22.2701 5.07034 21.2001 3.78034 17.2301L2.50034 13.2801C1.22034 9.3101 2.28034 7.2101 6.25034 5.9301L7.83034 5.4101C8.24034 5.2801 8.63034 5.1701 9.00034 5.1001C8.70034 5.7101 8.46034 6.4501 8.26034 7.3001L7.28034 11.4901C6.30034 15.6701 7.59034 17.7301 11.7603 18.7201L13.4403 19.1201C14.0203 19.2601 14.5603 19.3501 15.0603 19.3901Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </span>
        </div>
        <div
          className="max-w-sm mt-2 text-center text-white font-main overflow-handler md:max-w-md lg:max-w-lg xl:max-w-xl lg:mt-10"
          data-aos="fadu-up"
        >
          Or our Avalanche wallet:
        </div>
        <div
          className="flex max-w-sm mt-1 mb-2 text-center text-white underline gap-x-2 font-main overflow-handler md:max-w-md lg:max-w-lg xl:max-w-xl lg:mb-10"
          data-aos="fadu-up"
        >
          0xA7C3799e0ad4B233eAB7eFDedD361cd12e5b380D
          <span
            className="cursor-pointer "
            onClick={() => {
              navigator.clipboard.writeText("0xA7C3799e0ad4B233eAB7eFDedD361cd12e5b380D");
              toast.success("Address copied to clipboard!");
            }}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M21.6602 10.44L20.6802 14.62C19.8402 18.23 18.1802 19.69 15.0602 19.39C14.5602 19.35 14.0202 19.26 13.4402 19.12L11.7602 18.72C7.59018 17.73 6.30018 15.67 7.28018 11.49L8.26018 7.30001C8.46018 6.45001 8.70018 5.71001 9.00018 5.10001C10.1702 2.68001 12.1602 2.03001 15.5002 2.82001L17.1702 3.21001C21.3602 4.19001 22.6402 6.26001 21.6602 10.44Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M15.0603 19.3901C14.4403 19.8101 13.6603 20.1601 12.7103 20.4701L11.1303 20.9901C7.16034 22.2701 5.07034 21.2001 3.78034 17.2301L2.50034 13.2801C1.22034 9.3101 2.28034 7.2101 6.25034 5.9301L7.83034 5.4101C8.24034 5.2801 8.63034 5.1701 9.00034 5.1001C8.70034 5.7101 8.46034 6.4501 8.26034 7.3001L7.28034 11.4901C6.30034 15.6701 7.59034 17.7301 11.7603 18.7201L13.4403 19.1201C14.0203 19.2601 14.5603 19.3501 15.0603 19.3901Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </span>
        </div>
        <a
          href="https://twitter.com/nft_joemama?s=11"
          target="_blank"
          className="flex items-center tl:justify-center"
          rel="noreferrer"
        >
          <button
            data-aos="fadu-up"
            className="items-center justify-center px-3 py-1 text-black transition-all duration-100 bg-white rounded lg:ml-2 lg:text-lg sm:text-xs sm:my-2 font-main hover:text-white hover:scale-110 hover:bg-black"
          >
            Joe Mama's House
            <i className="p-2 rounded-full fa-brands fa-x-twitter hover:text-white"></i>
          </button>
        </a>
      </div>
      {/* <div className="flex items-center justify-center mb-10 lg:rounded-xl lg:w-[300rem] w-full md:w-[50rem]">
        <img
          src={sideImage}
          alt="logo"
          className="md:w-[380px] md:h-[380px] w-[300px] h-[300px] -mt-20 rounded-full cursor-pointer "
        />
      </div> */}
    </div>
  );
}

export default ContentArea2;
