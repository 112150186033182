import { BigNumber, ethers } from "ethers";
import { NFTCONTRACTADDRESS, NFTCONTRACTABI } from "./constants.js";
import { MetaMaskSDK } from "@metamask/sdk";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getBalance } from "./constants.js";
import { DateTime } from 'luxon';

let web3;
let provider;
let signer;
let myNFTContract;
let userBalance;

const options = {
  useDeeplink: false,
  communicationServerUrl: "https://metamask-sdk-socket.metafi.codefi.network/",
  autoConnect: {
    enable: true,
  },
};

const MMSDK = new MetaMaskSDK(options);
console.log(MMSDK);
// const ethereum = MMSDK.getProvider();

// Check for ethereum provider
if (typeof ethereum !== "undefined") {
  console.log("MetaMask is installed!");

  // Create provider and signer
  provider = new ethers.providers.Web3Provider(window.ethereum);
  signer = provider.getSigner();

  // Create contract instance
  myNFTContract = new ethers.Contract(
    NFTCONTRACTADDRESS,
    NFTCONTRACTABI,
    signer
  );
} else {
  console.error("Please install MetaMask!");
}

async function mintNFT(count) {
  const currentDateTimeInCalifornia = DateTime.now().setZone('America/Los_Angeles');
  const mintStartDate = DateTime.fromISO("2023-09-01T16:00:00", { zone: 'America/Los_Angeles' });

  if (currentDateTimeInCalifornia < mintStartDate) {
    toast.error("Minting will start on 1st September 2023");
    throw new Error("Minting will start on 1st September 2023");
  }
  
  if (!window.ethereum.selectedAddress) {
    toast.error("Please connect your wallet first");
    throw new Error("Please connect your wallet first");
  }

  const chainId = await window.ethereum.request({ method: "eth_chainId" });
  if (chainId !== "0xa86a") {
    toast.error("Please switch to the Avalanche Mainnet");
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0xa86a" }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0xa86a",
                chainName: "Avalanche Mainnet",
                rpcUrls: ["https://rpc.ankr.com/avalanche"],
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
          toast.error("Error while adding Avalanche Mainnet");
          throw addError;
        }
      }
      // handle other "switch" errors
      toast.error("Error while switching to Avalanche Mainnet");
      throw switchError;
    }
    return;
  }

  const userBalance = await getBalance(window.ethereum.selectedAddress);
  console.log(userBalance);

  // console.log(userBalance);
  // console.log(typeof userBalance);

  // console.log(BigNumber.from(userBalance.toString()));
  // console.log(BigNumber.from("1000000000000000000").mul(count));

  // console.log(
  //   BigNumber.from(userBalance.toString()).lt(
  //     BigNumber.from("1000000000000000000").mul(count)
  //   )
  // );

  if (
    userBalance < BigNumber.from("1000000000000000000").mul(count).toBigInt()
  ) {
    toast.error(`Insufficient funds to mint NFTs. ${count} AVAX required.`);
    return;
  }

  if (!myNFTContract) {
    toast.error("Contract is not initialized.");
    return;
  }
  try {
    const tx = await myNFTContract.mint(count, {
      gasLimit: 500000,
      value: ethers.utils.parseUnits(`${count}`, "ether"),
    });
    await tx.wait();
    toast.success("NFT Minted Successfully!");
  } catch (err) {
    console.log(err);
    toast.error("There was an error while minting your NFT.");
    throw new Error("There was an error while minting your NFT.");
  }
}

async function connectMetamask() {
  if (!window.ethereum) {
    toast.error("Metamask is required to interact with this dapp!");
    return;
  }

  try {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    const chainId = await window.ethereum.request({ method: "eth_chainId" });
    if (chainId !== "0xa86a") {
      toast.error("Please switch to the Avlanche Mainnet");
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0xa86a" }],
        });
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: "0xa86a",
                  chainName: "Avalanche Mainnet",
                  rpcUrls: ["https://rpc.ankr.com/avalanche"],
                },
              ],
            });
          } catch (addError) {
            // handle "add" error
            toast.error("Error while adding Avalanche Mainnet");
          }
        }
        // handle other "switch" errors
        toast.error("Error while switching to Avalanche Mainnet");
      }
      return;
    }

    toast.success("Wallet connected successfully!");
    return accounts[0]; // return the connected account address
  } catch (err) {
    console.log(err);
    toast.error("Error while connecting wallet.");
  }
}

export { mintNFT, connectMetamask };
