import "../App.css";
import { useState, useEffect } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import logo from "../logo.svg";
import mintImg from "../Assets/Final.gif";
import sideImage from "../Assets/sideImage.jpg";
import { mintNFT } from "../blockchain";
import { readOnlyContract } from "../constants";
import AOS from "aos";
import "aos/dist/aos.css";
library.add(fab);

function ContentArea3() {
  const [count, setCount] = useState(1);
  const totalSupply = 1000;
  const [totalMinted, setTotalMinted] = useState(0);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  });
  useEffect(() => {
    async function getTotalMinted() {
      const totalMinted = await readOnlyContract.totalSupply();
      console.log(parseInt(totalMinted.toString()));
      console.log(typeof parseInt(totalMinted.toString()));
      setTotalMinted(parseInt(totalMinted.toString()));
    }
    getTotalMinted();
  }, []);

  const incCount = () => {
    if (count < 5) setCount(count + 1);
  };
  const decCount = () => {
    if (count > 1) setCount(count - 1);
  };
  return (
    <div
      className="container mx-auto flex flex-col items-center justify-center h-auto px-4 bg-fixed bg-no-repeat bg-cover mint"
      style={{
        backgroundImage: "url('/assets/GoodBG-fce47d46.webp')",
      }}
    >
      <h1
        data-aos="fade-up"
        className="flex lg:text-7xl font-main text-center text-[#eeeeee] text-5xl font-bold pt-28 mb-12 lg:mb-20"
      >
        Minting Mamas
      </h1>
      <div
        data-aos="fade-up"
        className="h-auto max-w-[705px] px-10 lg:text-lg text-center text-white bg-gradient3 py-7 font-main text-sm bg-opacity-40 rounded-3xl backdrop-blur-md"
      >
        <p>
          {totalMinted < totalSupply ? (
            <>
              Minting is in progress. Joes have minted {totalMinted} Mamas.
              There are {totalSupply - totalMinted} Mamas left to mint.{" "}
            </>
          ) : (
            <>
              Minting is now over. Thank you to all the Joe who joined the
              movement, now we go and do what we do best, we farm.
            </>
          )}
        </p>
        <p className="mt-4">
          <a
            href="https://joepegs.com/collections/avalanche/0x32027c98c166d779a77673fc98a45cc333912b2a"
            target="_blank"
            className="underline"
            rel="noreferrer"
          >
            Click here for the official Joepegs link.
          </a>
        </p>
      </div>
      {/* <a
        data-aos="fade-up"
        className="px-8 py-2 mt-5 font-bold text-black bg-gradient3 rounded font-main hover:bg-black/50 hover:text-white"
        href="#FAQ"
      >
        FAQ
      </a> */}

      <div className="w-10/12 mt-20" data-aos="fade-right">
        <div className="flex flex-col w-full rounded">
          <div className="relative w-full border-[2px] border-white bg-black/50 bg-opacity-30 overflow-hidden backdrop-blur-md rounded-full h-12">
            <div
              className="relative flex items-center justify-end h-full bg-[#e9da24] shadow-xl rounded-full bg-opacity-60"
              style={{
                width: `${(totalMinted / totalSupply) * 100}%`,
              }}
            ></div>
            <span className="absolute top-0 left-0 flex items-center justify-center w-full h-full text-white text-md tl:text-sm font-main font-bold">
              {/* Mint Complete! */}
              {totalMinted < totalSupply ? (
                <>
                  {totalMinted} / {totalSupply} Mamas Minted
                </>
              ) : (
                <>Minting Complete!</>
              )}
            </span>
          </div>
        </div>
      </div>
      <div
        className="flex h-auto mt-20 transition-transform duration-300 lg:mt-20 hover:scale-110 relative z-10"
        data-aos="zoom-in"
      >
        <div className="grid items-center grid-cols-1 gap-10 lg:p-5 lg:justify-between lg:items-center ">
          <div className="text-center">
            <div className="flex flex-col px-5 py-5 lg:flex-row img-box w-auto md:mx-10 lg:mx-4 justify-center items-center bg-gradient3 relative rounded-3xl">
              <img
                src={mintImg}
                alt="Samurai"
                className="flex lg:p-4 opacity-90 w-full h-full lg:w-[30rem] lg:h-[30rem] rounded-3xl"
              />
              <div className="flex flex-col items-center justify-between w-full gap-2 px-5 py-3 text-white bg-black/50-to-br lg:p-5">
                <div className="flex items-center text-xl font-main lg:text-3xl sm:text-lg font-bold">
                  Mint Joe Mama NFT
                </div>
                <div className="flex flex-row px-5 mt-4 text-white lg:text-xl font-main sm:text-md">
                  <div className="flex flex-col justify-center w-full gap-8">
                    <p className="border-b border-white">
                      Minting Price : 1 AVAX
                    </p>
                    <p className="">
                      Total Price : {(1 * count).toFixed(1)} AVAX
                    </p>
                  </div>
                </div>
                <div className="text-white">
                  <div className="items-center">
                    <div className="flex flex-row">
                      <button
                        className="border border-white text-white w-[2rem] h-[2rem] hover:bg-white hover:text-black hover:border-black rounded-xl transform-transition duration-300 hover:scale-110"
                        onClick={decCount}
                      >
                        <p className="text-xl">-</p>
                      </button>
                      <p className="w-[4rem] font-main text-xl">{count}</p>
                      <button
                        className="border border-white text-white w-[2rem] h-[2rem] hover:bg-white hover:text-black hover:border-black text-xl rounded-xl transform-transition duration-300 hover:scale-110"
                        onClick={incCount}
                      >
                        +
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center justify-center mt-8 lg:mt-12">
                    <div className="font-main bg-[#e84142] font-semibold py-1 px-4 rounded-xl text-white w-28 text-lg hover:text-white transition-transform duration-300 hover:scale-110">
                      <button
                        onClick={() => {
                          mintNFT(count)
                            .then(() => {
                              setTotalMinted(totalMinted + count);
                            })
                            .catch((err) => {});
                        }}
                      >
                        Mint
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex text-sm items-center justify-center animate-[bounce_2s_ease-in-out_infinite] mt-5 font-main pb-5">
              <p className="text-transparent font-main">-</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentArea3;
