import "../App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { useEffect } from "react";
import emperorImage from "../Assets/emperor.png";
import sideImage from "../Assets/sideImage.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
library.add(fab);

function ContentArea6() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  });
  function toggleAnswer(questionId) {
    const answer = document.getElementById(`answer${questionId}`);
    const question = document.getElementById(`question${questionId}`);

    question.classList.toggle("rounded-b-none");
    answer.classList.toggle("hidden");
  }
  return (
    <div id="RoadMap" className="faq flex flex-col items-center justify-center overflow-hidden">
      <h1 className="my-12 lg:my-32 md:my-20 text-4xl text-center text-white font-bold font-main lg:text-7xl">
        Road Map
      </h1>

      <div className="flex items-center justify-center p-8 overflow-hidden">
        {/* Timeline Line */}
        <div className="absolute left-1/2 top-45 transform -translate-x-1/2 h-[43rem] lg:h-[53rem] w-1 border-l-2 border-[#e9da24] shadow-white" data-aos="zoom-in-up"></div>

        {/* Roadmap Steps */}
        <div className="flex flex-col">
          <div
            className="flex justify-between space-x-48 lg:space-x-40 mb-10"
            data-aos="fade-right"
          >
            <div className="text-center hover:shadow-red-500/50 border-[#e9da24] border hover:border-white mb-6 rounded-lg shadow-2xl bg-black/40 transform transition-all duration-300 hover:scale-110 p-4 lg:w-72 lg:py-4 w-[11rem]">
              <p className="text-white font-main lg:text-3xl font-bold m-1 text-xs">
                NFT 1
              </p>
              <p className="text-white font-main lg:text-xl lg:p-2 lg:m-1 text-xs">
                Joe Mama series 1 NFT
              </p>
            </div>
            <div className=" lg:w-72 lg:py-4"></div>
          </div>
          {/* Empty div for spacing */}
          <div
            className="flex justify-between space-x-48 lg:space-x-40 mb-10"
            data-aos="fade-left"
          >
            <div className=" lg:w-72 lg:py-4"></div>
            <div className="text-center hover:shadow-red-500/50 border-[#e9da24] border hover:border-white mb-6 rounded-lg shadow-2xl bg-black/40 transform transition-all duration-300 hover:scale-110 p-4 lg:w-72 lg:py-4 w-[11rem]">
              <p className="text-white font-main lg:text-3xl font-bold m-1 text-xs">
                NFT 2
              </p>
              <p className="text-white font-main lg:text-xl lg:p-2 lg:m-1 text-xs">
                Joe Mama series 2 NFT
              </p>
            </div>
          </div>

          <div
            className="flex justify-between space-x-48 lg:space-x-40 mb-10"
            data-aos="fade-right"
          >
            <div className="text-center hover:shadow-red-500/50 border-[#e9da24] border hover:border-white mb-6 rounded-lg shadow-2xl bg-black/40 transform transition-all duration-300 hover:scale-110 p-4 lg:w-72 lg:py-4 w-[11rem]">
              <p className="text-white font-main lg:text-3xl font-bold m-1 text-xs">
                Airdrop
              </p>
              <p className="text-white font-main lg:text-xl lg:p-2 lg:m-1 text-xs">
                Joe Mama community will receive a special airdrop
              </p>
            </div>
            <div className=" lg:w-72 lg:py-4"></div>
          </div>
          {/* Empty div for spacing */}
          <div
            className="flex justify-between space-x-48 lg:space-x-40 mb-10"
            data-aos="fade-left"
          >
            <div className=" lg:w-72 lg:py-4"></div>
            <div className="text-center hover:shadow-red-500/50 border-[#e9da24] border hover:border-white mb-6 rounded-lg shadow-2xl bg-black/40 transform transition-all duration-300 hover:scale-110 p-4 lg:w-72 lg:py-4 w-[11rem]">
              <p className="text-white font-main lg:text-3xl font-bold m-1 text-xs">
                LP For New Coin
              </p>
              <p className="text-white font-main lg:text-xl lg:p-2 lg:m-1 text-xs">
                A fun new piece to the Joe Mama ecosystem will become available to the community
                NFTs.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="w-full px-5 lg:px-0 lg:w-7/12 mb-5">
        <div id="question1" className="faq-header bg-white rounded-xl" onClick={() => toggleAnswer(1)}>
          <h2 className="text-[#201b22] font-bold text-sm lg:text-lg font-main">
            What is the collection supply?{" "}
          </h2>
          <span className="text-[#201b22]">+</span>
        </div>
        <div className="hidden p-3 bg-white faq-content rounded-b-xl" id="answer1">
          <div className="text-[#201b22] text-sm lg:text-lg font-main">
            The Samurai NFT mint will be capped at $1m. We are capping the
            collection at a $ value due to the nature of our Tier system, we
            will not know the final amount of NFTs until the $1m cap is reached.
          </div>
        </div>
      </div>
      <div className="w-full px-5 lg:px-0 lg:w-7/12 mb-5 ">
        <div
          id="question2"
          className="bg-white faq-header rounded-xl"
          onClick={() => toggleAnswer(2)}
        >
          <h2 className="text-[#201b22] font-bold text-sm lg:text-lg font-main">
            What do the different Tiers mean?
          </h2>
          <span className="text-[#201b22]">+</span>
        </div>
        <div className="hidden bg-white faq-content rounded-b-xl" id="answer2">
          <div className="p-3 text-[#201b22] text-sm lg:text-lg font-main">
            <p>
              In order to fairly distribute any potential future rewards to
              Samurai NFT holders, we have incorporated a "shares" system.
              Allowing us to fairly and systematically redistribute any
              potential rewards across Tiers.
              <br />
              <br />
              We have incentivised users to mint higher Tier NFTs by giving
              bonus shares to our Tier 2 and 3 Samurai. This is achieved by
              weighting the reward, resulting in Tier 2 achieving a 17% bonus,
              and a Tier 3 Samurai achieving a 40% bonus.
              <br />
              <br />
              For a better visualization of this weighting, please interact with
              the Calculator.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full px-5 lg:px-0 lg:w-7/12 mb-5">
        <div id="question3"
          className="bg-white faq-header rounded-xl"
          onClick={() => toggleAnswer(3)}
        >
          <h2 className="text-[#201b22] font-bold text-sm lg:text-lg font-main">
            What if you miss an airdrop?
          </h2>
          <span className="text-[#201b22]">+</span>
        </div>
        <div className="hidden bg-white faq-content rounded-b-xl" id="answer3">
          <div className="p-3 text-[#201b22] text-sm lg:text-lg font-main">
            <p>
              Although we have a strong track record and have been previously
              successful, there is no guarantee that we will deploy our strategy
              in a way that matches the criteria of every airdrop.
              <br />
              <br />
              We are confident in our strategies, but we do not guarantee any
              rewards, at any time.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full px-5 lg:px-0 lg:w-7/12 mb-5">
        <div
          id="question4"
          className="bg-white faq-header rounded-xl"
          onClick={() => toggleAnswer(4)}
        >
          <h2 className="text-[#201b22] font-bold text-sm lg:text-lg font-main">
            Do my potential rewards get diluted as more people mint?
          </h2>
          <span className="text-[#201b22]">+</span>
        </div>
        <div className="hidden bg-white faq-content rounded-b-xl" id="answer4">
          <div className=" p-3 text-[#201b22] text-sm lg:text-lg font-main">
            <p>
              The answer is no. It is, in fact, fairly simple, your reward will
              always remain the same.
              <br />
              <br />
              As more people mint = larger Sybil treasury/fund = more on-chain
              activity = larger airdrop. <br />
              <br />
              Therefore, although your share of the Sybil treasury decreases as
              more people mint (if 1 person mints, they own 100%, 2 people = 50%
              each etc.), the actual value of your potential reward remains the
              same as there is now more capital in the fund and therefore a
              larger potential for an airdrop. Don't worry anon, we've done the
              maths and you're all good.
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
      <div className="w-full px-5 lg:px-0 lg:w-7/12 mb-5">
        <div
          id="question5"
          className="bg-white faq-header rounded-xl px-3"
          onClick={() => toggleAnswer(5)}
        >
          <h2 className="text-[#201b22] font-bold text-sm lg:text-lg font-main">
            If you're so successful, why do this publicly?
          </h2>
          <span className="text-[#201b22]">+</span>
        </div>
        <div className="hidden bg-white faq-content rounded-b-xl" id="answer5">
          <div className="p-3 text-[#201b22] text-sm lg:text-lg font-main">
            <p>
              It is important to note that we also farm with our own money
              privately, in a totally separate operation. Seeing as we already
              have the structure to farm, and the demand is visibly there, we
              thought why not open this up to the public too? <br />
              <br />
              Offering our services to the public does not affect our existing
              operation or dilute our expected returns.
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default ContentArea6;
