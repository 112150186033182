import "../App.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { useEffect } from "react";
import emperorImage from "../Assets/emperor.png";
import mintImage2 from "../Assets/mintImg2.png";
import mintImage3 from "../Assets/mintImg3.png";
import mintImage4 from "../Assets/mintImg4.png";
import sideImage from "../Assets/sideImage.jpg";
import coolLogo from "../Assets/coolLogo.jpg";
import opLogo from "../Assets/opLogo.jpg";
import dash1 from "../Assets/dash4.png";
import dash2 from "../Assets/dash5.png";
import dash3 from "../Assets/dash3.png";
import cloud from '../Assets/bigCloud2.png'
import AOS from "aos";
import "aos/dist/aos.css";
library.add(fab);

function ContentArea5() {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  });
  return (
    <div className="container flex flex-col items-center px-4 py-8 mx-auto home lg:py-0 lg:px-14 lg:flex-row lg:h-auto -z-10">
      {/* <div className="flex flex-col items-center justify-center text-center md:pr-10 lg:items-start lg:text-left" data-aos="fade-right">
        <h1 className="px-2 mt-8 text-5xl font-bold text-white font-main lg:text-7xl md:mt-0 py-9">
          Estimated Previous Airdrops.
        </h1>
        <p className="w-full py-4 text-sm text-center text-white lg:text-lg lg:text-left font-main lg:w-10/12">
          Like the ancient Mama, we are masters of our craft and practice
          patience and precision in order to achieve our goals. These are
          qualities that are very rewarding when it comes to the art of Mama,
          our activities have been very fruitful in the past, and we hope to
          continue our prosperous journey.
        </p>
        <div className="flex justify-center mt-5 mb-5 space-x-2 lg:mb-0">
          <img
            src={coolLogo}
            className="w-12 rounded-full tl:w-8"
            alt="Icon1"
          />
          <img src={opLogo} className="w-12 rounded-full tl:w-8" alt="Icon2" />
        </div>
      </div> */}
      <div className="flex items-center justify-center p-2 my-8 md:my-0 w-full md:min-w-[500px] xl:min-w-[588px] floating-div2" data-aos="fade-left">
        <div className="flex items-center justify-center font-main">
          <div className="flex flex-col h-auto p-2 px-4 py-10 text-center rounded-3xl tl:p-5">
            <div>
              <img src={cloud} alt="cloud" className="mx-auto" />
            </div>

            {/* <div className="mt-8 text-sm text-center font-main tl:text-sm">
              Joe Mama launched on the 30th of July 2023, if we had been
              operational and farming <a className="font-main">0 month(s)</a>{" "}
              prior to our launch we estimate the potential rewards as above.
              <br />
              <br />
              <a
                href="https://sybil-samurai.gitbook.io/sybil-samurai/reward-calculator."
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="ml-1 text-sm underline cursor-help">
                  More info here
                </span>
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContentArea5;
